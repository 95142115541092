@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@800&display=swap');
.jfwfields label{
  font-family: 'Mulish', sans-serif;
  font-weight: bold; 
  margin-bottom: 0.5rem; 
    font-size: 1.5rem;
}
.jfwfields input,
.jfwfields textarea,
.jfwfields button,
.jfwfields select ,
.jfwfields select option {
  font-family: 'Mulish';
}
.jfwfields {
    display: flex;
    flex-direction: column;
    margin-bottom: 1.5rem; /* Space between form fields */
    width: 100%; /* Make the field take full width */
  }
  .jfwfields_one{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0.5rem;
    gap: 0.5rem;
    margin-bottom: 1.5rem; /* Space between form fields */
    width: fit-content; /* Make the field take full width */
    border-radius: 1rem;
    background: radial-gradient(circle, rgba(212, 203, 203, 0.703) 60%, #f1f1f1);
    box-shadow: 0px 0px 8px rgba(13, 12, 12, 0.331);
  }
  .jfwfields_button{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem;
    gap: 0.5rem;
    background-color:#217FB5;
    border-radius: 0.6rem;
    border:1px solid gray;
    cursor: pointer;
    height: 5rem;
  }
  sup{
    color: red;
  }
  .jfwfields_button > p {
    font-size: 1.5rem;
  }
  .jfwfields input, .jfwfields select{
    padding: 1rem; /* Padding inside the input field */
    width: 100%; /* Make the input field fill the available width */
    border: 1px solid #ccc; /* Add a light border */
    border-radius: 4px; /* Rounded corners for inputs */
    font-size: 1.5rem; /* Set font size inside input fields */
    box-sizing: border-box; /* Ensure padding and border are included in width */
    height: 5rem;
    color: rgba(51, 46, 46, 0.89);
  }
  .subtitle{
    font-size: 1.5rem;
    font-weight: bold;
    color: #4b4846e7;
  }
  .jfwfields textarea {
    width: 100%;  
    resize: vertical;  
    padding: 10px; 
    border-radius: 5px;  
    border: 1px solid #ccc;  
    box-sizing: border-box; 
    font-size: 1.5rem;
    height: 8rem;
    color: rgba(51, 46, 46, 0.89);
  }
  h2 {
    font-size: 2.5rem; /* Font size */
    font-weight: bold; /* Make the text bold */
    margin-top: 1rem; /* Add space above the h2 */
    margin-bottom: 0.5rem; /* Add space below the h2 */
  }